<template>
  <div class="dialog-add-patient">
    <v-dialog persistent :value="value" max-width="1080px">
      <v-card>
        <v-toolbar dense dark class="primary">
          <v-toolbar-title>新增病患資料</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="form" v-model="valid" :disabled="requesting">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  clearable
                  id="name"
                  label="姓名"
                  name="name"
                  prepend-icon="mdi-account-circle"
                  v-model="patientInfo.name"
                  :rules="[inputRules.required]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="性別"
                  prepend-icon="mdi-gender-male-female"
                  v-model="patientInfo.gender"
                  :items="genderList"
                  :rules="[() => !!patientInfo.gender || '必須的']"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  clearable
                  id="medicalOrderNumber"
                  label="病歷號"
                  name="medicalOrderNumber"
                  prepend-icon="mdi-clipboard-account"
                  v-model="patientInfo.medicalOrderNumber"
                  :rules="[() => !!patientInfo.medicalOrderNumber || '必須的']"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  clearable
                  id="id"
                  label="健保卡卡號"
                  name="id"
                  prepend-icon="mdi-card-account-details"
                  v-model="patientInfo.cardId"
                  :rules="[() => !!patientInfo.cardId || '必須的']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="時段"
                  prepend-icon="mdi-clipboard-clock-outline"
                  v-model="patientInfo.period"
                  :items="
                    Array(5)
                      .fill()
                      .map((val, idx) => idx + 1)
                  "
                  :rules="[() => !!patientInfo.period || '必須的']"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="診號"
                  prepend-icon="mdi-content-paste"
                  v-model="patientInfo.consultationNo"
                  :items="
                    Array(5)
                      .fill()
                      .map((val, idx) => idx + 1)
                  "
                  :rules="[() => !!patientInfo.consultationNo || '必須的']"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="健保卡身分"
                  prepend-icon="mdi-smart-card"
                  v-model="patientInfo.status"
                  :items="statusList"
                  :rules="[() => !!patientInfo.status || '必須的']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="科別"
                  prepend-icon="mdi-city"
                  v-model="patientInfo.department"
                  :items="departments"
                  :rules="[() => !!patientInfo.department || '必須的']"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  clearable
                  id="arrears"
                  label="欠費"
                  name="arrears"
                  prepend-icon="mdi-currency-usd"
                  v-model="patientInfo.arrears"
                  :rules="[() => !!patientInfo.arrears || '必須的']"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  clearable
                  id="remark"
                  label="備註"
                  name="remark"
                  prepend-icon="mdi-note"
                  v-model="patientInfo.remark"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" class="blue darken-1" text @click="submit()" :disabled="!valid">
            提交
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay v-model="requesting">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';

// import { firestore } from '@/firebase';

export default Vue.component(
  'dialog-add-patient',
  Vue.extend({
    name: 'AddPatientDialog',
    mixins: [extensions, messageSnackbar, inputRules],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      patientInfo: {
        period: null,
        consultationNo: null,
        name: '',
        medicalOrderNumber: '',
        status: '',
        department: '',
        gender: '',
        cardId: '',
        remark: '',
        registerTime: null,
        arrears: null,
        selected: false,
      },
      statusList: ['兒童', '一般', '身障', '敬老'],
      departments: ['內科', '外科'],
      genderList: ['男', '女'],
      // patientListItems: [],
      valid: false,
      requesting: false,
    }),
    methods: {
      async submit() {
        await (() => {
          this.requesting = true;
          this.patientInfo.registerTime = new Date();
          this.patientInfo.path = '/diagnosis/' + this.patientInfo.medicalOrderNumber;
          // return firestore.collection('patients').add(this.patientInfo);
          // this.$store.state.patientInfo.push(this.patientInfo);
          const newPatientArray = [];
          newPatientArray.push(this.patientInfo);
          return this.$store.dispatch('createNewPatient', newPatientArray);
        })()
          .then(() => {
            this.$store.dispatch('fetchPatientInfo');
          })
          .then(() => {
            this.close();
          })
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message),
          );
        this.requesting = false;
      },
      close() {
        this.patientInfo = {
          period: null,
          consultationNo: null,
          name: '',
          medicalOrderNumber: '',
          status: '',
          department: '',
          cardId: '',
          remark: '',
          registerTime: null,
          arrears: '',
        };
        this.$refs.form.resetValidation();
        this.$emit('input', false);
      },
    },
  }),
);
</script>
