import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { zhHant } from 'vuetify/lib/locale';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'xs',
    scrollBarWidth: 8,
  },
  lang: {
    locales: { zhHant },
    current: 'zhHant',
  },
  theme: {
    // dark: true,
    // default: 'light',
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: process.env.THEME_COLOR,
        // secondary: '#424242',
        // accent: '#82b1ff',
        // error: '#ff5252',
        // info: '#2196f3',
        // success: '#4caf50',
        // warning: '#fb8c00',
      },
      dark: {
        primary: process.env.THEME_COLOR,
        // secondary: '#424242',
        // accent: '#ff4081',
        // error: '#ff5252',
        // info: '#2196f3',
        // success: '#4caf50',
        // warning: '#fb8c00',
      },
    },
  },
});
