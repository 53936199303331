import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCvOHuz1VF8cBx5fhrU4ceV0QJlljeNBZ0',
  authDomain: 'tcm-ndhu.firebaseapp.com',
  projectId: 'tcm-ndhu',
  storageBucket: 'tcm-ndhu.appspot.com',
  messagingSenderId: '605446724936',
  appId: '1:605446724936:web:84abe85a53477b27a9a77d',
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
