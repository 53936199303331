import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import { firestore } from '@/firebase';
import supabase from '@/plugins/supabase';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: process.env.APP_NAME,
    themeColor: process.env.THEME_COLOR,
    package: {
      version: process.env.PACKAGE_VERSION,
      author: process.env.PACKAGE_AUTHOR,
      contributors: process.env.PACKAGE_CONTRIBUTORS,
    },
    signed: false,
    profile: {
      uid: null,
      name: null,
      role: null,
      email: null,
      photoUrl: null,
    },

    patientInfo: [],
    currentPatientDiagnosisInfo: [],
    tempSymptom: {},
    tempMedication: [],

    searchedPatientByTime: [],
    searchResult: [],

    user: null,
    patientId: '',
    diagnosisId: '77HfRggFC7AuUJEPna0b',
    // patientListItems: [],
    patient: null,
    patientCollection: null,
    diagnosisInfo: null,
    patientDiagnosisArray: [],
  },
  mutations: {
    sign(state, user) {
      state.signed = user ? true : false;
      state.profile.uid = user ? user.uid : null;
      state.profile.name = user ? user.displayName : null;
      state.profile.role = user ? user.role : null;
      state.profile.email = user ? user.email : null;
      state.profile.photoUrl = user ? user.photoURL : null;
    },
    // patientListItems(state, patientListItems) {
    //   state.patientListItems = patientListItems;
    // },
    patientId(state, patientId) {
      state.patientId = patientId;
    },
    patientTempStore(state, patientDiagnosis) {
      if (patientDiagnosis.idx === -1) {
        state.patientDiagnosisArray.push(patientDiagnosis.data);
      } else {
        state.patientDiagnosisArray[patientDiagnosis.idx] = patientDiagnosis.data;
      }
    },
    removePatientTempStore(state, idx) {
      state.patientDiagnosisArray.splice(idx, 1);
    },
    ...vuexfireMutations,
    patientInfoData(state, data) {
      state.patientInfo = data;
    },
    patientDiagnosisInfoData(state, data) {
      state.currentPatientDiagnosisInfo = data;
    },
    patientMedicationData(state, data) {
      state.tempMedication = data;
    },
    patientSymptomData(state, data) {
      state.tempSymptom = Object.assign({}, data);
    },
    resetTempStore(state) {
      state.currentPatientDiagnosisInfo = [];
      state.tempMedication = [];
      Object.getOwnPropertyNames(state.tempSymptom).forEach((prop) => {
        delete state.tempSymptom[prop];
      });
    },
    searchedPatientData(state, data) {
      state.searchedPatientByTime = data;
    },
    filterResult(state, filterInfo) {
      state.searchResult = state.searchedPatientByTime.filter(
        (patient) =>
          patient.name.includes(filterInfo.name) &&
          String(patient.medicalOrderNumber).includes(filterInfo.medicalOrderNumber),
      );
    },
    clearSearchResult(state) {
      state.searchedPatientByTime = [];
      state.searchResult = [];
    },
    checkingData() {
      return;
    },
  },
  actions: {
    bindUser: firestoreAction((context, userId) =>
      context.bindFirestoreRef('user', firestore.collection('users').doc(userId)),
    ),
    unbindUser: firestoreAction((context, userId) =>
      context.unbindFirestoreRef('user', firestore.collection('users').doc(userId)),
    ),
    bindWaitingPatient: firestoreAction((context) =>
      context.bindFirestoreRef(
        'patientCollection',
        firestore.collection('patients').orderBy('registerTime', 'asc'),
      ),
    ),
    unbindWaitingPatient: firestoreAction((context) =>
      context.unbindFirestoreRef('patientCollection', firestore.collection('patients')),
    ),
    bindDiagnosisInfo: firestoreAction((context, diagnosisId) =>
      context.bindFirestoreRef('diagnosisInfo', firestore.collection('diagnosis').doc(diagnosisId)),
    ),
    unbindDiagnosisInfo: firestoreAction((context, diagnosisId) =>
      context.unbindFirestoreRef(
        'diagnosisInfo',
        firestore.collection('diagnosis').doc(diagnosisId),
      ),
    ),
    bindPatient: firestoreAction((context, patientId) =>
      context.bindFirestoreRef('patient', firestore.collection('patients').doc(patientId)),
    ),
    unbindPatient: firestoreAction((context, patientId) =>
      context.unbindFirestoreRef('patient', firestore.collection('patients').doc(patientId)),
    ),
    subscribeToPatientInfoChange() {
      supabase
        .from('patientInfo')
        .on('*', () => {})
        .subscribe();
    },
    subscribeToDiagnosisInfoChange() {
      supabase
        .from('patientDiagnosis')
        .on('*', () => {})
        .subscribe();
    },
    async fetchPatientInfo({ commit }) {
      const { data: patientInfoData } = await supabase.from('patientInfo').select('*');
      commit('patientInfoData', patientInfoData);
    },
    async createNewPatient({ commit }, newPatient) {
      await supabase.from('patientInfo').insert(newPatient);
      commit('checkingData');
    },
    async updatePatientInfo({ commit }, newData) {
      await supabase
        .from('patientInfo')
        .update(newData.updateSelected)
        .eq('patientInfoKey', newData.key);
      commit('checkingData');
    },
    async fetchPatientDiagnosisInfoData({ commit }, medicalOrderNumber) {
      const { data: currentPatientDiagnosisInfo } = await supabase
        .from('patientDiagnosisInfo')
        .select(`id, medicalHist, physique, medicalOrderNumber, diseaseName`)
        .eq('medicalOrderNumber', medicalOrderNumber);
      commit('patientDiagnosisInfoData', currentPatientDiagnosisInfo);
    },
    async fetchPatientMedication({ commit }, medicalOrderNumber) {
      const { data: medication } = await supabase
        .from('patientDiagnosisInfo')
        .select('medication')
        .eq('medicalOrderNumber', medicalOrderNumber);
      if (medication[0].medication.length > 0) {
        const arrayData = JSON.parse(medication[0].medication);
        commit('patientMedicationData', arrayData);
      }
    },
    async fetchPatientSymptom({ commit }, medicalOrderNumber) {
      const { data: symptom } = await supabase
        .from('patientDiagnosisInfo')
        .select('symptom')
        .eq('medicalOrderNumber', medicalOrderNumber);
      if (symptom.length > 0) {
        if (typeof symptom[0].symptom === 'string') {
          const objectData = JSON.parse(symptom[0].symptom);
          commit('patientSymptomData', objectData);
        } else if (typeof symptom[0].symptom === 'object') {
          commit('patientSymptomData', symptom[0].symptom);
        }
      }
    },
    async createPatientDiagnosisInfo({ commit }, newDiagnosis) {
      const { data: newPatientDiagnosisInfo } = await supabase
        .from('patientDiagnosisInfo')
        .insert(newDiagnosis);
      commit('patientDiagnosisInfoData', newPatientDiagnosisInfo);
    },
    async updatePatientDiagnosisInfo({ commit }, newData) {
      await supabase.from('patientDiagnosisInfo').update(newData).eq('id', newData.id);
      commit('checkingData');
    },
    resetTempStore({ commit }) {
      commit('resetTempStore');
    },
    async searchPatientInfoData({ commit }, searchInfo) {
      let searchedPatientInfo;
      if (searchInfo[0] && searchInfo[1]) {
        searchedPatientInfo = await supabase
          .from('patientInfo')
          .select(`medicalOrderNumber, name, path`)
          .gte('registerTime', searchInfo[0])
          .lts('registerTime', searchInfo[1]);
      } else if (searchInfo[0] && !searchInfo[1]) {
        searchedPatientInfo = await supabase
          .from('patientInfo')
          .select(`medicalOrderNumber, name, path`)
          .gte('registerTime', searchInfo[0]);
      } else if (!searchInfo[0] && searchInfo[1]) {
        searchedPatientInfo = await supabase
          .from('patientInfo')
          .select(`medicalOrderNumber, name, path`)
          .lte('registerTime', searchInfo[1]);
      } else {
        searchedPatientInfo = await supabase
          .from('patientInfo')
          .select(`medicalOrderNumber, name, path`);
      }
      commit('searchedPatientData', searchedPatientInfo.data);
    },
    filterResultData({ commit }, filterInfo) {
      commit('filterResult', filterInfo);
    },
    clearSearchResultData({ commit }) {
      commit('clearSearchResult');
    },
  },
  modules: {},
});
