import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
  },
  {
    path: '/diagnosis/:medicalOrderNumber',
    name: 'Diagnosis',
    component: () => import(/* webpackChunkName: "diagnosis" */ '@/views/Diagnosis'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
