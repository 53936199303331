<template>
  <v-app>
    <v-navigation-drawer
      v-if="isCurrentPathInNavListItems"
      v-model="navDrawerOpen"
      app
      clipped
      :permanent="!$vuetify.breakpoint.mobile"
      :touchless="!$vuetify.breakpoint.mobile"
    >
      <v-list dense>
        <v-list-item v-for="(item, i) in navListItems" :key="`navListItem${i}`" :to="item.path">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group :value="true" prepend-icon="mdi-account-group" v-if="patientListItems">
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>
                待診病患（{{ Object.keys(patientListItems).length }}）
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-if="Object.keys(patientListItems).length > 0">
            <v-list-item
              v-for="(item, i) in patientListItems"
              :key="`medicalOrderNumber${i}`"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon>mdi-clipboard-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>病歷號：{{ item.medicalOrderNumber }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.prevent="removeSelectedPatient(item.patientInfoKey)"
                  @mousedown.native.stop
                  @touchend.native.stop
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item disabled>
              <v-list-item-content>
                <v-list-item-title>目前沒有病患…</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-group
          :value="true"
          prepend-icon="mdi-account-group"
          v-if="$store.state.searchResult.length > 0"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>
                查詢病患（{{ $store.state.searchResult.length }}）
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template>
            <v-list-item
              v-for="(item, i) in $store.state.searchResult"
              :key="`seachedMedicalOrderNumber${i}`"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon>mdi-clipboard-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  病歷號：{{ item.medicalOrderNumber }} - {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
      <template #append>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-btn
                v-if="$store.state.profile.name === 'Demo'"
                color="info"
                block
                @click="isOpenAddPatientDialog = true"
              >
                <v-icon left>mdi-account-multiple-plus-outline</v-icon>
                新增病患資料
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="warning" block @click="isOpenSearchDialog = true">
                <v-icon left>mdi-clipboard-text-search-outline</v-icon>
                查詢歷史病例
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-navigation-drawer>
    <v-app-bar app clipped-left color="primary" dark dense>
      <template v-if="isCurrentPathInNavListItems">
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.mobile"
          @click.stop="navDrawerOpen = !navDrawerOpen"
        />
        <v-icon v-else class="ml-n3" style="width: 48px; height: 48px">mdi-sprout</v-icon>
      </template>
      <v-btn v-else icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0 text-md-h5 text-subtitle-1">
        {{ $store.state.appName }}
      </v-toolbar-title>
      <v-spacer />
      <v-menu
        v-if="$store.state.signed"
        offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            text
            class="pa-1 mx-1"
            min-width="0"
            height="48"
            :style="{
              borderRadius: `${$vuetify.breakpoint.mobile ? 24 : 16}px`,
            }"
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar class="ma-1" size="32">
              <img v-if="$store.state.profile.photoUrl" :src="$store.state.profile.photoUrl" />
              <img v-else src="@/assets/images/grey_silhouette.png" />
            </v-avatar>
            <span
              v-if="!$vuetify.breakpoint.mobile"
              class="ma-1 subtitle-1"
              style="text-transform: none"
              v-text="$store.state.profile.name"
            />
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img v-if="$store.state.profile.photoUrl" :src="$store.state.profile.photoUrl" />
                <img v-else src="@/assets/images/grey_silhouette.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $store.state.profile.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ $store.state.profile.email }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="msgPop('此功能尚未開放')">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="pink" text @click="signOut()">登出</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-menu v-model="menuOpen" left offset-y transition="fade-transition">
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon :small="$vuetify.breakpoint.mobile">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <v-list-item-icon>
              <v-icon>
                {{ $vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-white-balance-sunny' }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ `主題：${$vuetify.theme.dark ? '暗' : '亮'}` }}
            </v-list-item-title>
          </v-list-item>
          <about-dialog v-model="aboutDialogOpen" @input="$event ? null : (menuOpen = false)">
            <template #activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                  <v-icon>mdi-information</v-icon>
                </v-list-item-icon>
                <v-list-item-title>關於</v-list-item-title>
              </v-list-item>
            </template>
          </about-dialog>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path" style="height: calc(100vh - 48px)" />
      </transition>
      <add-patient-dialog v-model="isOpenAddPatientDialog" />
      <search-dialog v-model="isOpenSearchDialog" />
    </v-main>
    <sign-in-register-dialog v-model="signingIn" />
    <v-overlay v-model="requesting">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_showing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-app>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script>
import Vue from 'vue';
// import firebase from 'firebase/app';

import AboutDialog from '@/components/App/AboutDialog';
import AddPatientDialog from '@/components/App/AddPatientDialog';
import SignInRegisterDialog from '@/components/App/SignInRegisterDialog';
import SearchDialog from '@/components/App/SearchDialog';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { auth } from '@/firebase';

export default Vue.extend({
  name: 'App',
  components: {
    AboutDialog,
    AddPatientDialog,
    SignInRegisterDialog,
    SearchDialog,
  },
  mixins: [messageSnackbar],
  data: () => ({
    navListItems: [
      {
        title: '首頁',
        path: '/',
        icon: 'mdi-home',
      },
    ],
    navDrawerOpen: false,
    menuOpen: false,
    aboutDialogOpen: false,
    requesting: false,
    signIn: false,
    isOpenAddPatientDialog: false,
    isOpenSearchDialog: false,
    signingIn: false,
  }),
  computed: {
    isCurrentPathInNavListItems() {
      const paths = this.navListItems.map((item) => item.path);
      if (this.$store.state.user) {
        paths.push(...Object.values(this.$store.state.user.ownPatients).map((item) => item.path));
      }
      return paths.includes(this.$route.path);
    },
    patientListItems() {
      const patientListItems = this.$store.state.patientInfo.filter(
        (item) => item.selected === false,
      );
      return patientListItems;
    },
  },
  methods: {
    async removeSelectedPatient(uuid) {
      const updateParameters = { key: uuid, updateSelected: { selected: true } };
      await this.$store.dispatch('updatePatientInfo', updateParameters);
      await this.$store.dispatch('fetchPatientInfo');
    },
    msgPop(str) {
      this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, str, 'close');
    },
    async signOut() {
      this.requesting = true;
      this.$store.dispatch('unbindUser', this.$store.state.profile.uid);
      this.$store.dispatch('unbindWaitingPatient');
      this.$store.dispatch('unbindDiagnosisInfo', this.$store.state.diagnosisId);
      await auth
        .signOut()
        .catch((error) =>
          this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, 'close'),
        );
      this.requesting = false;
    },
    // async removePatient(number, id) {
    //   this.requesting = true;
    //   this.$_mixin_messageSnackbar_hide();
    //   await firestore
    //     .collection('users')
    //     .doc(this.$store.state.profile.uid)
    //     .update({
    //       [`ownPatients.${id}`]: firebase.firestore.FieldValue.delete(),
    //     })
    //     .catch((error) =>
    //       this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
    //     );
    //   await firestore.collection('patients').doc(id).update({ selected: false });
    //   this.$store.commit(
    //     'removePatientTempStore',
    //     this.$store.state.patientDiagnosisArray.indexOf(
    //       this.$store.state.patientDiagnosisArray.filter((item) => item.id === id),
    //     ),
    //   );

    //   if (this.$store.state.patientId === id) {
    //     this.$store.dispatch('unbindPatient', this.$store.state.patientId);
    //   }
    //   if (number === this.$route.params.medicalOrderNumber) {
    //     this.$router.push({ name: 'Home' }) || [];
    //   }
    //   this.requesting = false;
    // },
  },
  async created() {
    this.requesting = true;
    this.signingIn = !this.$store.state.signed;
    if (this.$store.state.signed) {
      await this.$store.dispatch('bindUser', this.$store.state.profile.uid);
    }
    this.requesting = false;
    await this.$store.dispatch('fetchPatientInfo');
    this.$store.dispatch('subscribeToPatientInfoChange');
  },
});
</script>
