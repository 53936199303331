<template>
  <div class="dialog-add-patient">
    <v-dialog persistent :value="value" max-width="540px">
      <v-card>
        <v-toolbar dense dark class="primary">
          <v-toolbar-title>查詢病歷</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  clearable
                  id="medicalOrderNumber"
                  label="病歷號"
                  name="medicalOrderNumber"
                  prepend-icon="mdi-clipboard-account"
                  v-model="searchInfo.medicalOrderNumber"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  clearable
                  id="name"
                  label="姓名"
                  name="name"
                  prepend-icon="mdi-account-circle"
                  v-model="searchInfo.name"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-2">掛號日期</v-col>
              <v-col class="col-4">
                <v-text-field
                  clearable
                  id="registerTimeStart"
                  label="從"
                  name="registerTimeStart"
                  v-model="searchPeriod.registerTimeStart"
                  type="date"
                />
              </v-col>
              <v-col class="col-4">
                <v-text-field
                  clearable
                  id="registerTimeEnd"
                  label="到"
                  name="registerTimeEnd"
                  v-model="searchPeriod.registerTimeEnd"
                  type="date"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="clear()">清除</v-btn>
          <v-btn type="submit" class="blue darken-1" text @click="submit()">提交</v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay v-model="requesting">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import messageSnackbar from '@/mixins/messageSnackbar';

export default Vue.component(
  'search-dialog',
  Vue.extend({
    name: 'SearchDialog',
    mixins: [extensions, messageSnackbar, inputRules],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      searchInfo: {
        name: '',
        medicalOrderNumber: '',
      },
      searchPeriod: {
        registerTimeStart: null,
        registerTimeEnd: null,
      },
      requesting: false,
      valid: true,
    }),
    methods: {
      async submit() {
        this.requesting = true;
        const searchDate = [this.searchPeriod.registerTimeStart, this.searchPeriod.registerTimeEnd];
        await this.$store.dispatch('searchPatientInfoData', searchDate);
        this.$store.dispatch('filterResultData', this.searchInfo);
        this.close();
        this.requesting = false;
      },
      clear() {
        this.searchInfo.name = '';
        this.searchInfo.medicalOrderNumber = '';
        this.searchPeriod.registerTimeStart = null;
        this.searchPeriod.registerTimeEnd = null;
        this.$store.dispatch('clearSearchResultData');
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
    },
  }),
);
</script>
