<template>
  <v-dialog
    class="sign-in-register-dialog"
    :value="value"
    @input="$emit('input', $event)"
    persistent
    max-width="480px"
  >
    <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-btn
          icon
          :disabled="window === 'signIn'"
          @click="(window = 'signIn'), (signInData.password = '')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-icon left v-text="window === 'signIn' ? 'mdi-login-variant' : 'mdi-account-plus'" />
        <v-toolbar-title v-text="window === 'signIn' ? '登入' : '註冊'" />
        <v-spacer />
        <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          <v-icon v-text="$vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-white-balance-sunny'" />
        </v-btn>
      </v-toolbar>
      <v-window v-model="window">
        <v-window-item value="signIn">
          <v-card-text>
            <v-form
              :disabled="requesting"
              v-model="isSignInFormValid"
              id="signInForm"
              @submit.prevent
            >
              <v-text-field
                clearable
                id="email"
                label="信箱"
                name="email"
                prepend-icon="mdi-email"
                type="email"
                v-model="signInData.email"
                :rules="[inputRules.required, inputRules.email]"
                @input="localStorage.setItem('signIn.email', $event)"
              />
              <v-text-field
                clearable
                counter
                label="密碼"
                prepend-icon="mdi-lock"
                v-model="signInData.password"
                :append-icon="isPasswordShowable ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[inputRules.required, inputRules.password]"
                :type="isPasswordShowable ? 'text' : 'password'"
                @click:append="isPasswordShowable = !isPasswordShowable"
              />
            </v-form>
          </v-card-text>
        </v-window-item>
        <v-window-item value="register">
          <v-card-text>
            <v-form :disabled="requesting" v-model="isRegisterFormValid">
              <v-text-field
                clearable
                id="email"
                label="信箱"
                name="email"
                prepend-icon="mdi-email"
                type="email"
                v-model="signInData.email"
                :rules="[inputRules.required, inputRules.email]"
                @input="localStorage.setItem('signIn.email', $event)"
              />
              <v-text-field
                clearable
                counter
                label="密碼"
                prepend-icon="mdi-lock"
                v-model="signInData.password"
                :append-icon="isPasswordShowable ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[inputRules.required, inputRules.password]"
                :type="isPasswordShowable ? 'text' : 'password'"
                @click:append="isPasswordShowable = !isPasswordShowable"
              />
              <v-divider />
              <v-text-field
                clearable
                id="name"
                label="姓名"
                name="name"
                prepend-icon="mdi-account-circle"
                v-model="registerData.name"
                :rules="[inputRules.required]"
              />
              <v-select
                prepend-icon="mdi-account-details"
                v-model="registerData.role"
                :items="roles"
                label="身份"
              />
            </v-form>
          </v-card-text>
        </v-window-item>
      </v-window>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="window === 'signIn'"
          text
          color="warning"
          :disabled="requesting"
          @click="(signInData.password = ''), (window = 'register')"
        >
          註冊
        </v-btn>
        <v-spacer />
        <v-checkbox
          class="px-3 py-0 ma-0"
          label="記住我"
          v-model="isRememberMeChecked"
          hide-details
          dense
          :disabled="requesting"
          @click="localStorage.setItem('signIn.isRememberMeChecked', isRememberMeChecked)"
        />
        <v-btn
          type="submit"
          form="signInForm"
          color="primary"
          text
          :loading="requesting"
          :disabled="requesting || !(window === 'signIn' ? isSignInFormValid : isRegisterFormValid)"
          @click="window === 'signIn' ? signIn() : register()"
          v-text="window === 'signIn' ? '登入' : '註冊並登入'"
        />
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_showing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import blakejs from 'blakejs';
import firebase from 'firebase/app';

import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { auth, firestore } from '@/firebase';

export default Vue.component(
  'sign-in-register-dialog',
  Vue.extend({
    name: 'SignInRegisterDialog',
    mixins: [inputRules, messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      // TODO: make localStorage become Vue plugin https://bit.ly/2DsS4ES
      localStorage: window.localStorage,
      window: 'signIn',
      signInData: {
        email: '',
        password: '',
      },
      registerData: {
        name: '',
        role: '',
      },
      roles: ['醫師', '護理人員'],
      isSignInFormValid: false,
      isRegisterFormValid: false,
      isRememberMeChecked: false,
      isPasswordShowable: false,
      requesting: false,
    }),
    computed: {
      authPersistence() {
        return this.isRememberMeChecked
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION;
      },
    },
    methods: {
      async signIn() {
        this.$_mixin_messageSnackbar_hide();
        this.requesting = true;
        this.signInData.password = blakejs.blake2bHex(this.signInData.password);
        const userCredential = await auth
          .setPersistence(this.authPersistence)
          .then(() =>
            auth.signInWithEmailAndPassword(this.signInData.email, this.signInData.password),
          )
          .catch((error) => {
            if (error.code === 'auth/user-not-found') this.window = 'register';
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, '錯誤：' + error.message);
          });
        this.signInData.password = '';
        if (userCredential) {
          if (auth.currentUser) {
            this.$store.commit('sign', auth.currentUser);
            this.$emit('input', false);
          } else {
            this.$_mixin_messageSnackbar_show(
              MessageSnackbarType.error,
              'An unknown error has occurred.',
            );
          }
        }
        this.requesting = false;
      },
      async register() {
        this.$_mixin_messageSnackbar_hide();
        this.requesting = true;
        this.signInData.password = blakejs.blake2bHex(this.signInData.password);
        const userCredential = await auth
          .setPersistence(this.authPersistence)
          .then(() =>
            auth.createUserWithEmailAndPassword(this.signInData.email, this.signInData.password),
          )
          .catch((error) => {
            if (error.code === 'auth/email-already-in-use') this.signInData.email = '';
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, '錯誤：' + error.message);
          });
        this.signInData.password = '';
        if (userCredential) {
          if (auth.currentUser) {
            await auth.currentUser
              .updateProfile({
                displayName: this.registerData.name,
              })
              .then(() =>
                firestore
                  .collection('users')
                  .doc(auth.currentUser.uid)
                  .set({ role: this.registerData.role, ownPatients: Object() }),
              )
              .catch((error) =>
                this.$_mixin_messageSnackbar_show(
                  MessageSnackbarType.error,
                  '錯誤：' + error.message,
                ),
              );
            this.$store.commit('sign', auth.currentUser);
            this.$emit('input', false);
          } else {
            this.$_mixin_messageSnackbar_show(
              MessageSnackbarType.error,
              'An unknown error has occurred.',
            );
          }
        }
        this.requesting = false;
      },
    },
    created() {
      this.isRememberMeChecked =
        JSON.parse(localStorage.getItem('signIn.isRememberMeChecked')) === true;
      this.signInData.email = localStorage.getItem('signIn.email') ?? '';
    },
  }),
);
</script>
