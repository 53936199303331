import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    inputRules: {
      required(value) {
        if (typeof value === 'string') return value.trim().length > 0 ? true : '必須的';
        return value !== undefined && value !== null ? true : '必須的';
      },
      email(value) {
        // Reference: https://bit.ly/3hU8c2f
        const pattern =
          '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\' +
          's@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,' +
          '3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
        if (!new RegExp(pattern).test(value)) return '無效的信箱格式';
        return true;
      },
      password(value) {
        if (!/^[\x20-\x7e]+$/.test(value)) return '只能使用 0~9, A~Z, a~z 和常用的標點符號';
        else if (value.length < 6) return '至少六位數';
        return true;
      },
    },
  }),
});
